import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import cn from "classnames";
import { FluidScroll, Grid, Nav, SanitySlice, SEO, Sidebar } from "~components";
import { useSanitySlices } from "~hooks";
import * as styles from "./styles.module.scss";

/** ============================================================================
 * @page
 * ThreeJS playground
 */
const ThreePage = ({ data: staticData }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { allSanityPage } = staticData;
  const { pagebuilder } = allSanityPage.edges.find(
    ({ node }) => node?.slug?.current === `/`
  ).node;

  const { slices } = useSanitySlices(pagebuilder?.slices || []);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <FluidScroll>
        {slices?.map((slice) => (
          <SanitySlice key={slice._key} data={slice} />
        ))}
      </FluidScroll>
    </>
  );
};

export default ThreePage;

export const query = graphql`
  query Three {
    allSanityPage {
      edges {
        node {
          title
          slug {
            current
          }

          pagebuilder {
            slices {
              ...BorderArticleFragment
              ...FullVideoFragment
              ...SimpleImageFragment
              ...SimpleVideoFragment
              ...TextArticleFragment
            }
          }
        }
      }
    }
  }
`;
